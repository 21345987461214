import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { storyblokInit, apiPlugin } from '@storyblok/react';

import Page from './components/Page';
import HomeTitle from './components/homeTitle';
import EllySection from './components/EllySection';
import Servizi from './components/Servizi';
import InfiniteScroll from './components/InfiniteScroll';
import Works from './components/Works';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Team from './components/Team';
import Meta from './components/Meta';

storyblokInit({
    accessToken: '1SGSdnpUmhx1ImO1mraOJQtt',
    use: [apiPlugin],
    components: {
        page: Page,
        Home: HomeTitle,
        Sezione3lly: EllySection,
        Servizi: Servizi,
        Partners: InfiniteScroll,
        Lavori: Works,
        Contatti: Contact,
        footer_link: Footer,
        Team: Team,
        Meta: Meta,
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
