import { useLottie, useLottieInteractivity } from 'lottie-react';
import Elly from '../assets/3lly.json';

const options = {
    animationData: Elly,
};

function EllyLottie() {
    const lottieObj = useLottie(options);
    const Animation = useLottieInteractivity({
        lottieObj,
        mode: 'scroll',
        container: '#lottie-container',
        actions: [
            {
                visibility: [0, 0.2],
                type: 'stop',
                frames: [1],
            },
            {
                visibility: [0.2, 0.5],
                type: 'seek',
                frames: [1, 29],
            },
            {
                visibility: [0.5, 1.0],
                type: 'stop',
                frames: [30],
            },
        ],
    });
    return Animation;
}

export default EllyLottie;
