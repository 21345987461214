import React, { useEffect, useState } from 'react';
import { ReactComponent as Plus } from '../assets/svg/plus.svg';
import { ReactComponent as Logo } from '../assets/svg/3sy-wht.svg';
import Popup from './Popup';

function TeamCard({ name, role, description, image, i }) {
    const [openBio, setOpenBio] = useState(false);

    const handleBio = () => {
        setOpenBio((current) => !current);
    };

    useEffect(() => {
        if (openBio) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [openBio]);

    return (
        <>
            <div onClick={handleBio} key={i} mou className='team-main'>
                <div className='team-img-frame'>
                    <Plus className='plus-icon' />
                    <div style={{ background: image ? `url('${image}')` : `black`, backgroundSize: 'cover' }} className='team-image'></div>
                </div>
                <div className='team-details'>
                    <h2>{name}</h2>
                    <p>{role}</p>
                </div>
            </div>
            {openBio ? <Popup setOpenBio={setOpenBio} name={name} role={role} description={description} image={image} /> : null}
        </>
    );
}

export default TeamCard;
