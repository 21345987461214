import React, { memo } from 'react';
import Card from './Card';
import { storyblokEditable } from '@storyblok/react';

function Servizi({ blok }) {
    const MemoizedListItem = memo(Card);

    const backBlur = blok.Blur;
    return (
        <div className='services-cards' {...storyblokEditable(blok)}>
            {blok.ListaServizi.map((item, index) => {
                return <MemoizedListItem blur={backBlur} circle={index === 0 ? true : false} key={index} title={item.Titolo} description={item.Descrizione} image={item.Immagine} index={index} />;
            })}
        </div>
    );
}

export default memo(Servizi);
