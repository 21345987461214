import React, { useEffect } from 'react';
import { storyblokEditable } from '@storyblok/react';

function EllySection({ blok }) {
    useEffect(() => {
        // const title = document.getElementById('elly-title');
        // title.innerHTML = blok.Titolo;

        const paragraph = document.getElementById('elly-p');
        const aria = document.getElementById('aria');
        paragraph.innerHTML = blok.Paragrafo;
        aria.setAttribute('aria-label', `${blok.Paragrafo}`);
    }, []);
    return (
        <div className='secret-text-container' id='aria'>
            {/* <h2 id='elly-title' {...storyblokEditable(blok)}></h2> */}
            <h2>
                Our Secret:
                <br />
                <span style={{ fontSize: 64 }}>3lly</span>
            </h2>
            <p id='elly-p' {...storyblokEditable(blok)}></p>
        </div>
    );
}

export default EllySection;
