import React from 'react';
import '../style/InfiniteScroll.css';

import { storyblokEditable } from '@storyblok/react';

function InfiniteScroll({ blok, speed = 35000, mobileSpeed = 15000 }) {
    const isMobile = window.innerWidth <= 700;

    return (
        <>
            <div style={{ marginTop: isMobile ? 40 : 65 }} className='services-title border'>
                <h5>{blok.Title}</h5>
            </div>
            {blok.PartnersList.length > 5 || isMobile ? (
                <div {...storyblokEditable(blok)} className='inner'>
                    <div className='wrapper'>
                        <section style={{ '--speed': `${isMobile ? mobileSpeed : speed}ms` }}>
                            {blok.PartnersList.map((item, index) => {
                                return (
                                    <>
                                        {item.link.url === '' ? (
                                            <a key={index}>
                                                <div className='image' key={index}>
                                                    <img src={item.Logo} alt='alt' />
                                                </div>
                                            </a>
                                        ) : (
                                            <a key={index} target='_blank' href={`${item.link.url}`}>
                                                <div className='image' key={index}>
                                                    <img src={item.Logo} alt='alt' />
                                                </div>
                                            </a>
                                        )}
                                    </>
                                );
                            })}
                        </section>
                        <section style={{ '--speed': `${isMobile ? mobileSpeed : speed}ms` }}>
                            {blok.PartnersList.map((item, index) => {
                                return (
                                    <>
                                        {item.link.url === '' ? (
                                            <a key={index}>
                                                <div className='image' key={index}>
                                                    <img src={item.Logo} alt='alt' />
                                                </div>
                                            </a>
                                        ) : (
                                            <a key={index} target='_blank' href={`${item.link.url}`}>
                                                <div className='image' key={index}>
                                                    <img src={item.Logo} alt='alt' />
                                                </div>
                                            </a>
                                        )}
                                    </>
                                );
                            })}
                        </section>
                        <section style={{ '--speed': `${isMobile ? mobileSpeed : speed}ms` }}>
                            {blok.PartnersList.map((item, index) => {
                                return (
                                    <>
                                        {item.link.url === '' ? (
                                            <a key={index}>
                                                <div className='image' key={index}>
                                                    <img src={item.Logo} alt='alt' />
                                                </div>
                                            </a>
                                        ) : (
                                            <a key={index} target='_blank' href={`${item.link.url}`}>
                                                <div className='image' key={index}>
                                                    <img src={item.Logo} alt='alt' />
                                                </div>
                                            </a>
                                        )}
                                    </>
                                );
                            })}
                        </section>
                    </div>
                </div>
            ) : (
                <section {...storyblokEditable(blok)} className='less-cont'>
                    {blok.PartnersList.map((item, index) => {
                        return (
                            <>
                                {item.link.url === '' ? (
                                    <a key={index}>
                                        <div className='image' key={index}>
                                            <img src={item.Logo} alt='alt' />
                                        </div>
                                    </a>
                                ) : (
                                    <a key={index} target='_blank' href={`${item.link.url}`}>
                                        <div className='image' key={index}>
                                            <img src={item.Logo} alt='alt' />
                                        </div>
                                    </a>
                                )}
                            </>
                        );
                    })}
                </section>
            )}
        </>
    );
}

export default InfiniteScroll;
