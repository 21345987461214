import React, { useState } from 'react';
import '../style/Navbar.css';

import { ReactComponent as Logo } from '../assets/svg/3sy.svg';
import { ReactComponent as Arrow } from '../assets/svg/arrow.svg';

function Navbar({ progress }) {
    const [show, setShow] = useState(false);

    const DIAMETER = 16;
    const STROKE_WIDTH = 1.2;
    const RADIUS = DIAMETER / 2 - STROKE_WIDTH / 2;

    const CIRCUMFERENCE = Math.PI * RADIUS * 2;

    const position = Math.max(1 - progress, 0);

    const isMobile = window.innerWidth <= 700;

    const showMenu = () => {
        setShow((current) => !current);
    };
    const closeMenu = () => {
        setShow(false);
    };

    return (
        <>
            {isMobile ? (
                <nav style={{ opacity: position <= 0.02 ? 0 : 1, pointerEvents: position <= 0.02 ? 'none' : 'unset' }} onMouseLeave={closeMenu}>
                    <div onClick={showMenu} id='menu-mobile' className='mobile'>
                        <div className='loader'>
                            <svg viewBox='0 0 16 16' width='28px' height='28px' className='circle-progress'>
                                <circle
                                    cx={DIAMETER / 2}
                                    cy={DIAMETER / 2}
                                    r={RADIUS}
                                    stroke-width={STROKE_WIDTH}
                                    style={{
                                        strokeDasharray: CIRCUMFERENCE,
                                        strokeDashoffset: CIRCUMFERENCE * position,
                                    }}
                                    stroke='#000'
                                    fill='transparent'
                                />
                            </svg>
                        </div>
                        <h2>Menu</h2>
                        <div style={{ borderRadius: 40 }} className='blur'></div>
                    </div>
                    <div style={{ opacity: show ? 1 : 0, pointerEvents: show ? 'unset' : 'none' }} className='right'>
                        <ul>
                            <a onClick={showMenu} href='#about-sec'>
                                <li>about</li>
                            </a>
                            <a onClick={showMenu} href='#services-sec'>
                                <li>services</li>
                            </a>
                            <a onClick={showMenu} href='#team-sec'>
                                <li>team</li>
                            </a>
                            <a onClick={showMenu} href='#works-sec'>
                                <li>works</li>
                            </a>
                        </ul>
                        <a onClick={showMenu} href='#contact-sec'>
                            <li className='contact-header-btn'>
                                contact
                                <span>
                                    <Arrow />
                                </span>
                            </li>
                        </a>
                        <div style={{ opacity: show ? 1 : 0, pointerEvents: show ? 'unset' : 'none' }} className='blur'></div>
                    </div>
                </nav>
            ) : (
                <nav className='border'>
                    <div className='left'>
                        <a style={{ margin: !isMobile ? '-20px 0px' : null }} href='#home'>
                            <Logo />
                        </a>
                    </div>
                    <div className='right'>
                        <ul>
                            <a href='#about-sec'>
                                <li>about</li>
                            </a>
                            <a href='#services-sec'>
                                <li>services</li>
                            </a>
                            <a href='#team-sec'>
                                <li>team</li>
                            </a>
                            <a href='#works-sec'>
                                <li>works</li>
                            </a>
                            <a href='#contact-sec'>
                                <li className='contact-header-btn'>
                                    contact
                                    <span>
                                        <Arrow />
                                    </span>
                                </li>
                            </a>
                        </ul>
                        <div className='loader'>
                            <svg viewBox='0 0 16 16' width='28px' height='28px' className='circle-progress'>
                                <circle
                                    cx={DIAMETER / 2}
                                    cy={DIAMETER / 2}
                                    r={RADIUS}
                                    stroke-width={STROKE_WIDTH}
                                    style={{
                                        strokeDasharray: CIRCUMFERENCE,
                                        strokeDashoffset: CIRCUMFERENCE * position,
                                    }}
                                    stroke='#000'
                                    fill='transparent'
                                />
                            </svg>
                        </div>
                    </div>
                    <div className='blur'></div>
                </nav>
            )}
        </>
    );
}

export default Navbar;
