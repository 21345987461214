import React from 'react';
import TeamCard from './TeamCard';

function Team({ blok }) {
    const teamBlok = blok.teamBlock;
    const isMobile = window.innerWidth <= 700;

    const nRow = 2;
    return (
        <>
            {teamBlok.map((item, i) => {
                return (
                    <div className='team-frame' style={{ justifyContent: item.Person.length === 3 ? 'space-between' : 'space-evenly', marginTop: i === 0 && !isMobile ? 50 : 0 }}>
                        {item.Person.map((item, i) => {
                            return <TeamCard name={item.name} role={item.role} description={item.bio} image={item.profile_image} i={i} />;
                        })}
                    </div>
                );
            })}
        </>
    );
}

export default Team;
