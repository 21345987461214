import React from 'react';
import { storyblokEditable } from '@storyblok/react';

function HomeTitle({ blok }) {
    return (
        <>
            <h1 {...storyblokEditable(blok)}>{blok.Titolo}</h1>
            <p {...storyblokEditable(blok)}>{blok.Paragrafo}</p>
        </>
    );
}

export default HomeTitle;
