import React from 'react';
import { ReactComponent as Back } from '../assets/svg/back.svg';
import { ReactComponent as Logo } from '../assets/svg/3sy-line.svg';

function Popup({ setOpenBio, name, role, description, image }) {
    const handleBio = () => {
        setOpenBio((current) => !current);
    };
    return (
        <div className='popup'>
            <div className='popup-frame'>
                <div onClick={handleBio} className='back-icon'>
                    <Back className='back-size' />
                </div>
                <div className='popup-details-mobile'>
                    <Logo className='logo' />
                    <div>
                        <h2>{name}</h2>
                        <p>{role}</p>
                    </div>
                </div>
                <div className='popup-details'>
                    <div className='img-prof' style={{ backgroundImage: `url(${image})` }} />
                    <div>
                        <h2>{name}</h2>
                        <p>{role}</p>
                    </div>
                </div>
                <div className='popup-text'>
                    <h2>Biografia</h2>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
}

export default Popup;
