import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { ReactComponent as ArrowLink } from '../assets/svg/arrow-link.svg';

function Carousel({ bg, v1, v2, slides, slides2, slides3, slides4 }) {
    const isMobile = window.innerWidth <= 700;
    return (
        <>
            {slides ? (
                <div style={{ height: '100%' }}>
                    <Splide
                        hasTrack={false}
                        options={{
                            perPage: 1,
                            perMove: 1,
                            arrows: false,
                            width: '100%',
                            height: '100%',
                            pagination: true,
                            drag: slides.length > 1 ? true : false,
                        }}>
                        <SplideTrack style={{ height: '100%', borderRadius: 40 }}>
                            {slides.map((item, index) => {
                                return (
                                    <SplideSlide style={{ height: '100%', backgroundColor: `${item.background_color.color}` }} key={index}>
                                        {isMobile ? (
                                            <div
                                                aria-label={`${item.heading} - ${item.Titolo}`}
                                                className='splide-inner'
                                                style={{
                                                    backgroundImage: `url('${item.mobile_image}')`,
                                                }}>
                                                {item.heading != '' ? (
                                                    <div style={{ backgroundColor: `${item.keyword_background_color.color}` }}>
                                                        <h3 style={{ color: `${item.keywords_textColor.color}` }}>{item.heading}</h3>
                                                    </div>
                                                ) : null}
                                                {item.Titolo != '' ? <h2 style={{ color: `${item.titleColor.color}` }}>{item.Titolo}</h2> : null}
                                                {item.link.url != '' ? (
                                                    <a href={`${item.link.url}`} target='_blank'>
                                                        <ArrowLink style={{ marginTop: 20, color: `${item.arrow_color.color}` }} />
                                                    </a>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <div
                                                aria-label={`${item.heading} - ${item.Titolo}`}
                                                className='splide-inner'
                                                style={{
                                                    backgroundImage: `url('${item.image}')`,
                                                }}>
                                                {item.heading != '' ? (
                                                    <div style={{ backgroundColor: `${item.keyword_background_color.color}` }}>
                                                        <h3 style={{ color: `${item.keywords_textColor.color}` }}>{item.heading}</h3>
                                                    </div>
                                                ) : null}
                                                {item.Titolo != '' ? <h2 style={{ color: `${item.titleColor.color}` }}>{item.Titolo}</h2> : null}
                                                {item.link.url != '' ? (
                                                    <a href={`${item.link.url}`} target='_blank'>
                                                        <ArrowLink style={{ marginTop: 20, color: `${item.arrow_color.color}` }} />
                                                    </a>
                                                ) : null}
                                            </div>
                                        )}
                                    </SplideSlide>
                                );
                            })}
                        </SplideTrack>
                    </Splide>
                </div>
            ) : slides2 ? (
                <div style={{ height: '100%' }}>
                    <Splide
                        hasTrack={false}
                        options={{
                            perPage: 1,
                            perMove: 1,
                            arrows: false,
                            width: '100%',
                            height: '100%',
                            pagination: true,
                            drag: slides2.length > 1 ? true : false,
                        }}>
                        <SplideTrack style={{ height: '100%' }}>
                            {slides2.map((item, index) => {
                                return (
                                    <SplideSlide style={{ height: '100%', backgroundColor: `${item.background_color.color}` }} key={index}>
                                        <div
                                            aria-label={`${item.heading} - ${item.Titolo}`}
                                            className='splide-inner'
                                            style={{
                                                backgroundImage: isMobile ? `url('${item.mobile_image}')` : `url('${item.image}')`,
                                            }}>
                                            {item.heading != '' ? (
                                                <div style={{ backgroundColor: `${item.keyword_background_color.color}` }}>
                                                    <h3 style={{ color: `${item.keywords_textColor.color}` }}>{item.heading}</h3>
                                                </div>
                                            ) : null}
                                            {item.Titolo != '' ? <h2 style={{ color: `${item.titleColor.color}` }}>{item.Titolo}</h2> : null}
                                            {item.link.url != '' ? (
                                                <a href={`${item.link.url}`} target='_blank'>
                                                    <ArrowLink style={{ marginTop: 20, color: `${item.arrow_color.color}` }} />
                                                </a>
                                            ) : null}
                                        </div>
                                    </SplideSlide>
                                );
                            })}
                        </SplideTrack>
                    </Splide>
                </div>
            ) : slides3 ? (
                <div style={{ height: '100%' }}>
                    <Splide
                        hasTrack={false}
                        options={{
                            perPage: 1,
                            perMove: 1,
                            arrows: false,
                            width: '100%',
                            height: '100%',
                            pagination: true,
                            drag: slides3.length > 1 ? true : false,
                        }}>
                        <SplideTrack style={{ height: '100%' }}>
                            {slides3.map((item, index) => {
                                return (
                                    <SplideSlide style={{ height: '100%', backgroundColor: `${item.background_color.color}` }} key={index}>
                                        <div
                                            aria-label={`${item.heading} - ${item.Titolo}`}
                                            className='splide-inner'
                                            style={{
                                                backgroundImage: isMobile ? `url('${item.mobile_image}')` : `url('${item.image}')`,
                                            }}>
                                            {item.heading != '' ? (
                                                <div style={{ backgroundColor: `${item.keyword_background_color.color}` }}>
                                                    <h3 style={{ color: `${item.keywords_textColor.color}` }}>{item.heading}</h3>
                                                </div>
                                            ) : null}
                                            {item.Titolo != '' ? <h2 style={{ color: `${item.titleColor.color}` }}>{item.Titolo}</h2> : null}
                                            {item.link.url != '' ? (
                                                <a href={`${item.link.url}`} target='_blank'>
                                                    <ArrowLink style={{ marginTop: 20, color: `${item.arrow_color.color}` }} />
                                                </a>
                                            ) : null}
                                        </div>
                                    </SplideSlide>
                                );
                            })}
                        </SplideTrack>
                    </Splide>
                </div>
            ) : slides4 ? (
                <div style={{ height: '100%' }}>
                    <Splide
                        hasTrack={false}
                        options={{
                            perPage: 1,
                            perMove: 1,
                            arrows: false,
                            width: '100%',
                            height: '100%',
                            pagination: true,
                            drag: slides4.length > 1 ? true : false,
                        }}>
                        <SplideTrack style={{ height: '100%' }}>
                            {slides4.map((item, index) => {
                                return (
                                    <SplideSlide style={{ height: '100%', backgroundColor: `${item.background_color.color}` }} key={index}>
                                        {isMobile ? (
                                            <div
                                                aria-label={`${item.heading} - ${item.Titolo}`}
                                                className='splide-inner'
                                                style={{
                                                    backgroundImage: `url('${item.mobile_image}')`,
                                                }}>
                                                {item.heading != '' ? (
                                                    <div style={{ backgroundColor: `${item.keyword_background_color.color}` }}>
                                                        <h3 style={{ color: `${item.keywords_textColor.color}` }}>{item.heading}</h3>
                                                    </div>
                                                ) : null}
                                                {item.Titolo != '' ? <h2 style={{ color: `${item.titleColor.color}` }}>{item.Titolo}</h2> : null}
                                                {item.link.url != '' ? (
                                                    <a href={`${item.link.url}`} target='_blank'>
                                                        <ArrowLink style={{ marginTop: 20, color: `${item.arrow_color.color}` }} />
                                                    </a>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <div
                                                aria-label={`${item.heading} - ${item.Titolo}`}
                                                className='splide-inner'
                                                style={{
                                                    backgroundImage: `url('${item.image}')`,
                                                }}>
                                                {item.heading != '' ? (
                                                    <div style={{ backgroundColor: `${item.keyword_background_color.color}` }}>
                                                        <h3 style={{ color: `${item.keywords_textColor.color}` }}>{item.heading}</h3>
                                                    </div>
                                                ) : null}
                                                {item.Titolo != '' ? <h2 style={{ color: `${item.titleColor.color}` }}>{item.Titolo}</h2> : null}
                                                {item.link.url != '' ? (
                                                    <a href={`${item.link.url}`} target='_blank'>
                                                        <ArrowLink style={{ marginTop: 20, color: `${item.arrow_color.color}` }} />
                                                    </a>
                                                ) : null}
                                            </div>
                                        )}
                                    </SplideSlide>
                                );
                            })}
                        </SplideTrack>
                    </Splide>
                </div>
            ) : null}
        </>
    );
}

export default Carousel;
