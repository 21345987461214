import { useEffect } from 'react';
import './App.css';

import { useStoryblok, StoryblokComponent } from '@storyblok/react';

function App() {
    const pathname = '/' + window.location.pathname.split('/').pop();
    let slug = pathname === '/' ? 'it' : pathname.replace('/', '');

    let params = { version: 'publish' };

    useEffect(() => {
        const isDraft = window.location.search.split('=')[0];
        if (isDraft === '?_storyblok') {
            params.version = 'draft';
        }
    }, []);

    const story = useStoryblok(slug, params);
    if (!story || !story.content) {
        return <div></div>;
    }

    return <StoryblokComponent blok={story.content} />;
}

export default App;
