import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Navbar from './Navbar';
import '../App.css';
import '@splidejs/react-splide/css';

import { ReactComponent as Logo } from '../assets/svg/3sy.svg';

import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

import { getComponentByName } from '../shared/utilities';
import EllyLottie from './EllyLottie';

function Page({ blok }) {
    const pageRef = useRef();
    const [progress, setProgress] = useState(0);

    const homeHeight = useRef();
    const [divHeight, setDivHeight] = useState(0);

    useEffect(() => {
        if (!homeHeight.current) return;
        setDivHeight(homeHeight.current.offsetHeight);
    }, [homeHeight]);

    useLayoutEffect(() => {
        const updateHeight = () => {
            if (!pageRef.current) return;

            const { height } = pageRef.current.getBoundingClientRect();

            setProgress(window.scrollY / (height - window.innerHeight));
        };

        updateHeight();

        window.addEventListener('scroll', updateHeight);
        return () => {
            window.removeEventListener('scroll', updateHeight);
        };
    }, []);

    return (
        <>
            <StoryblokComponent blok={getComponentByName('Meta', blok)} />
            <div {...storyblokEditable(blok)} id='about-sec' ref={pageRef} className='App'>
                <Navbar progress={progress} />
                <div ref={homeHeight} id='home' className='presentation border'>
                    <Logo />
                    <StoryblokComponent blok={getComponentByName('Home', blok)} />
                </div>
                <div className='secret-container'>
                    <div className='secret'>
                        <div id='lottie-container' className='img-3lly'>
                            <EllyLottie />
                        </div>
                        <StoryblokComponent blok={getComponentByName('Sezione3lly', blok)} />
                    </div>
                </div>
                <div id='services-sec' className='services'>
                    <div className='services-title border'>
                        <h5>our services</h5>
                    </div>
                    <StoryblokComponent blok={getComponentByName('Servizi', blok)} />
                </div>
                <StoryblokComponent blok={getComponentByName('Partners', blok)} />
                <div id='team-sec' className='border'>
                    <div className='services-title'>
                        <h5>team</h5>
                    </div>
                    <StoryblokComponent blok={getComponentByName('Team', blok)} />
                </div>
                <div id='works-sec' className='works border'>
                    <div className='services-title'>
                        <h5>our works</h5>
                    </div>
                    <div className='works-frame-grid'>
                        <div className='works-grid'>
                            <StoryblokComponent blok={getComponentByName('Lavori', blok)} />
                        </div>
                    </div>
                </div>
                <div id='contact-sec' className='contacts'>
                    <div className='contacts-header'>
                        <h2>Contattaci adesso.</h2>
                        <p>Richiedi una chiamata, o scrivici semplicemente una e-mail.</p>
                    </div>
                    <div className='contacts-methods'>
                        <StoryblokComponent blok={getComponentByName('Contatti', blok)} />
                    </div>
                </div>
                <StoryblokComponent blok={getComponentByName('footer_link', blok)} />
            </div>
        </>
    );
}

export default Page;
