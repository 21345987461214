import React, { memo, useCallback, useEffect, useState } from 'react';
import Watermark from '@uiw/react-watermark';

function Card({ title, description, image, index, circle, blur }) {
    const [heightCard, setHeightCard] = useState(false);
    const isMobile = window.innerWidth <= 700;

    const openCard = useCallback(
        (event) => {
            setHeightCard(true);
        },
        [heightCard]
    );

    const closeCard = useCallback(
        (event) => {
            setHeightCard(false);
        },
        [heightCard]
    );

    const handleCard = useCallback(
        (event) => {
            setHeightCard((current) => !current);
        },
        [heightCard]
    );

    const pageOffset = window.addEventListener('scroll', () => {
        if (window.pageYOffset >= 1320) {
            document.getElementById('circle-anim').classList.add('press');
        }
    });

    return (
        <div
            onClick={isMobile ? handleCard : null}
            onMouseEnter={isMobile ? null : openCard}
            onMouseLeave={closeCard}
            className='service-card'
            aria-label={`${description}`}
            style={{
                height: !heightCard ? 165 : 350,
                background: image === null || image === undefined || image === '' ? '#000' : `url(${image})`,
                backgroundSize: '100%',
                position: 'relative',
            }}
            key={index}>
            {image ? null : <Watermark image='https://a.storyblok.com/f/160941/x/d916e19789/3sy-wht.svg'></Watermark>}
            <div className='service-cards-details'>
                {isMobile ? <h2 style={{ display: !heightCard ? 'block' : 'none' }}>{title}</h2> : <h2>{title}</h2>}
                <p style={{ display: !heightCard ? 'none' : 'block' }}>{description}</p>

                {circle ? <div id='circle-anim' style={{ opacity: heightCard ? 0 : 1 }} /> : null}
            </div>
            <div
                className='cards-blur'
                style={{
                    position: 'absolute',
                    top: 0,
                    backdropFilter: isMobile ? 'none' : `blur(${blur}px)`,
                    WebkitBackdropFilter: isMobile ? `blur(${blur}px)` : 'none',
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: heightCard ? '1' : '0',
                    transition: '300ms',
                    zIndex: 0,
                }}
            />
        </div>
    );
}

export default memo(Card);
