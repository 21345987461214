import React from 'react';
import '../style/Footer.css';
import { ReactComponent as Logo } from '../assets/svg/3sy-wht.svg';
import { ReactComponent as IG } from '../assets/svg/instagram.svg';
import { ReactComponent as IN } from '../assets/svg/linkedin.svg';

function Footer({ blok }) {
    const isMobile = window.innerWidth <= 700;
    return (
        <footer>
            {isMobile ? (
                <>
                    <div className='footer-header'>
                        <Logo />
                        <div className='footer-socials'>
                            <a target='_blank' href={`${blok.link_instagram.url}`}>
                                <IG />
                            </a>
                            <a target='_blank' href={`${blok.link_linkedin.url}`}>
                                <IN />
                            </a>
                        </div>
                    </div>
                    <div className='policy'>
                        <a href='https://firebasestorage.googleapis.com/v0/b/sy-website-93038.appspot.com/o/Privacy%20%26%20Cookie%20Policy%203sy.pdf?alt=media&token=0532140e-bfcd-455b-b80b-a1f39ec2d8a5'>
                            Privacy & Cookie Policy
                        </a>
                        <p>© 2023 3sy</p>
                    </div>
                </>
            ) : (
                <>
                    <div className='footer-header'>
                        <Logo />
                        <div className='policy'>
                            <p>© 2023 3sy</p>
                            <a href='https://firebasestorage.googleapis.com/v0/b/sy-website-93038.appspot.com/o/Privacy%20%26%20Cookie%20Policy%203sy.pdf?alt=media&token=0532140e-bfcd-455b-b80b-a1f39ec2d8a5'>
                                Privacy & Cookie Policy
                            </a>
                        </div>
                        <div className='footer-socials'>
                            <a target='_blank' href={`${blok.link_instagram.url}`}>
                                <IG />
                            </a>
                            <a target='_blank' href={`${blok.link_linkedin.url}`}>
                                <IN />
                            </a>
                        </div>
                    </div>
                </>
            )}
        </footer>
    );
}

export default Footer;
