import React from 'react';
import { ReactComponent as Camera } from '../assets/svg/camera.svg';
import { ReactComponent as Mail } from '../assets/svg/mail.svg';

function Contact({ blok }) {
    const mailHover = () => {
        document.getElementById('mail').style.color = '#000';
    };
    const mailLeave = () => {
        document.getElementById('mail').style.color = '#B7B7B7';
    };
    const cameraHover = () => {
        document.getElementById('camera').style.color = '#000';
    };
    const cameraLeave = () => {
        document.getElementById('camera').style.color = '#B7B7B7';
    };
    return (
        <>
            <a onMouseEnter={cameraHover} onMouseLeave={cameraLeave} href={`${blok.calendly.url}`} target='_blank'>
                <div className='methods-frame'>
                    <Camera id='camera' />
                    <div>
                        <h3>{blok.title_calendly}</h3>
                        <p>{blok.description_calendly}</p>
                    </div>
                </div>
            </a>
            <a onMouseLeave={mailLeave} onMouseEnter={mailHover} href={`mailto:${blok.email}`} target='_blank'>
                <div className='methods-frame'>
                    <Mail id='mail' />
                    <div>
                        <h3>{blok.title_email}</h3>
                        <p>{blok.description_email}</p>
                    </div>
                </div>
            </a>
        </>
    );
}

export default Contact;
