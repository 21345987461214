import React from 'react';
import { Helmet } from 'react-helmet';

function Meta({ blok }) {
    const seMeta = blok.Meta_searchEngine;
    const ogMeta = blok.Meta_OG;
    const twitterMeta = blok.Meta_Twitter;

    return (
        <>
            <Helmet>
                {/* Meta tags search engine */}
                {seMeta.map((item) => {
                    return <meta name={`${item.Name}`} content={`${item.Content}`} />;
                })}

                {/* Meta tags Open Graph */}
                {ogMeta.map((item) => {
                    return <meta property={`${item.property}`} content={`${item.content}`} />;
                })}

                {/* Meta tags Twitter */}
                {twitterMeta.map((item) => {
                    return <meta name={`${item.Name}`} content={`${item.Content}`} />;
                })}
            </Helmet>
        </>
    );
}

export default Meta;
