import React from 'react';
import Carousel from './Carousel';

function Works({ blok }) {
    return (
        <>
            <div className='carousel-1'>
                <Carousel slides={blok.carousel_one} v1={true} />
            </div>
            <div className='carousel-2'>
                <Carousel slides2={blok.carousel_two} v1={false} />
            </div>
            <div className='carousel-3'>
                <Carousel slides3={blok.carousel_three} v1={false} />
            </div>
            <div className='carousel-4'>
                <Carousel slides4={blok.carousel_four} v1={false} />
            </div>
            <div className='carousel-5'>
                <Carousel slides4={blok.carousel_five} v1={false} />
            </div>
        </>
    );
}

export default Works;
